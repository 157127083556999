.wrapper {
  height: 100%;
  /*border: 5px solid yellow;*/
}

.btn-main {
  margin-right: 1rem;
}

.results-temp {
  border: 0px solid red;
}

.flex-center {
  display: flex;
  align-items: center;
  flex-direction: column;
}

.hint {
  border: 2px solid #565758;
}