.tile {
    width: 4rem;
    height: 4rem;
    font-size: 2rem;
    font-weight: 900;
    text-transform: uppercase;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 2px solid #565758;
    box-shadow: 2px 2px 2px #000;
    color: #fff;
}

.letter {
    cursor: pointer;
    -webkit-touch-callout: none; /* iOS Safari */
      -webkit-user-select: none; /* Safari */
       -khtml-user-select: none; /* Konqueror HTML */
         -moz-user-select: none; /* Old versions of Firefox */
          -ms-user-select: none; /* Internet Explorer/Edge */
              user-select: none; /* Non-prefixed version, currently */
}

.activeLetter {
  border: 2px solid white;
}